body{
    background-color: rgba(52, 53, 65, 1);
    color:white;
    padding: 50px;
}

.input-form{
    width:500px;
    position: fixed;
    bottom: 0;
    margin-bottom: 50px;
}

.input{
    width: 80%;
    float: left;
    height:20px;
    padding: 8px;
}
button{
    width: 12%;
    float: left;
    height: 40px;
    border: none;
    background-color: #ED4D41;
    color: white;
}
.chat-box{
    text-align: left;
    float: left;
}
.bot{
    font-size: 20px;
    margin-top: 5px;

}


.user{
    font-size: 18px;
    margin-top: 30px;
    color: #ED4D41
}